<template>
  <CSidebarNav class="c-sidebar-nav ps" :class="{'settingsItemsSidebarHeight': isSettingItems}" >
    <CSidebarNavItem
      v-for="(item, index) in items"
      :key="index"
      :class="item._attrs.class"
    >
      <CLink :to="item.to" class="c-sidebar-nav-link">
        <CIcon
          class="c-sidebar-nav-icon zq-sidebar-custom-logo"
          v-if="logoType === 'svg'"
          :name="item.icon"
        />
        <img
          v-else
          :src="require(`../../../public/img/icons/${item.icon}.svg`)"
          :alt="`${item.name} logo`"
          class="c-sidebar-nav-icon zq-sidebar-custom-logo"
        />
        <span> {{ item.name }}</span>
      </CLink>
    </CSidebarNavItem>
  </CSidebarNav>
</template>

<script>
export default {
  props: {
    items: Array,
    logoType: {
      type: String,
      default: "svg",
    },
    isSettingItems: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style>
 .settingsItemsSidebarHeight {
   max-height: calc(100% - 150px) !important;
 }
</style>